import React from "react";

const Information = () => {
  return (
    <p className="p-4 text-center text-orange-500 text-2xl info">
      Click on the image, but don't click twice on the same one
    </p>
  );
};

export default Information;
